import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import ExternalLink from '../../common/ExternalLink/ExternalLink';
import TenantContext from '../../context/TenantContext';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * Class name(s) to apply to the child component element.
   */
  className?: string;
}

/**
 *
 * Component that displays Google ReCaptcha Privacy and Terms of Service if Google ReCaptcha is enabled
 *
 * @author Corey Aing, Willi Hyde
 */
const ReCaptchaPrivacyTos: React.FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const cx = useClassNameMapper();
  const tenant = useContext(TenantContext);
  const { FormattedMessage, loading: textLoading } = useTranslation(
    SharedComponent.RE_CAPTCHA_PRIVACY_TOS
  );

  const featureEnabled = tenant.publicConfig?.reCaptchaV3Enabled;

  if (!featureEnabled || textLoading) {
    return null;
  }

  return (
    <div className={cx(className, 'lia-g-mt-30 lia-g-mb-30')}>
      <small className={cx('text-muted text-center d-block')}>
        <FormattedMessage
          id="privacytos"
          values={{
            privacyLink: function renderPrivacyLink(chunks): React.ReactNode {
              return (
                <ExternalLink
                  className={cx('text-muted lia-g-underline')}
                  href="https://policies.google.com/privacy"
                >
                  {chunks}
                </ExternalLink>
              );
            },
            tosLink: function renderTosLink(chunks): React.ReactNode {
              return (
                <ExternalLink
                  className={cx('text-muted lia-g-underline')}
                  href="https://policies.google.com/terms"
                >
                  {chunks}
                </ExternalLink>
              );
            }
          }}
        />
      </small>
    </div>
  );
};

export default ReCaptchaPrivacyTos;
